a{
    .button-primary{
        margin-top: 16px;
        padding: 16px 32px;
        background-color: #003049;
        text-transform: uppercase;
        border: 2px solid transparent;
        width: fit-content;
        h4{
            font-weight: 400;
            display: block;
            color: #FCBF49;
        }
        &:hover{
            border: 2px solid #003049;
            background-color: transparent;
            cursor: pointer;
            h4{
                color: #003049;
            }
        }
    }
}
