.home-page{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .section{
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fcbf49;
        color: rgba(0,49,73,1);
        overflow: hidden;
        @media screen and (max-aspect-ratio: '3/4') {
            padding: calc(1/12 * 100vh) calc(1/12 * 100vw);
        }
        @media screen and (min-aspect-ratio: '4/3') {
            padding: calc(2/12 * 100vh) calc(1/12 * 100vw);
        }
    }
    .section-01{
        position: relative;
        width: 100%;
        min-height: 100vh;
        background-size: cover;
        background-position: center;
        .container{
            display: flex;
            flex-direction: column;
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
                flex-direction: row;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
        .triangle{
            height: auto;
            width: 120vw;
            position: absolute;
            @media (min-width: 768px) {
                height: 100vw;
                width: 100vw;
            }
            @media (min-width: 990px) {
                height: 80vh;
                width: 80vh;
            }
            @media (min-width: 1200px) {
                height: 80vh;
                width: 80vh;
            }
            svg{
                height: 100%;
                width: 100%;
                fill: rgba(255, 255, 255, 0.2);
            }
        }
    }
    .section-02{
        overflow: hidden;
        .benefits{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 16px;
                grid-row-gap: 16px;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
            @media (min-width: 1200px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 16px;
                grid-row-gap: 16px;
            }
        }
    }
    .section-03{
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .section-04{
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .section-05{
        @media screen {
            padding-bottom: calc(1/12 * 100vh);
        }
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .section-06{
        background-color: rgba(0,49,73,1);
        @media screen{
            padding: calc(1/12 * 100vh) calc(1/12 * 100vw);
        }
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .section-07{
        padding-bottom: 0;
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .section-08{
        @media screen {
            padding-bottom: calc(1/12 * 100vh);
        }
        .container{
            @media (min-width: 768px) {
                width: 80%;
                margin: auto;
            }
            @media (min-width: 990px) {
                max-width: 800px;
            }
        }
    }
    .triangle-small{
        position: absolute;
        top: 40px;
        right: 100px;
        z-index: 1001;
        overflow: hidden;
        transform: rotate(-45deg);
        svg{
            width: 100px;
            fill: rgba(255,255,255,0.2);
        }
    }
}

