@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: rgba(0,49,73,1);
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
  color: inherit;
}

h1{
  font-size: 2.4414rem;
}
h2{
  font-size: 1.9531rem;
}
h3{
  font-size: 1.5625rem;
}
h4{
  font-size: 1.25rem;
}
h5{
  font-size: 0.8rem;
}
h6{
  font-size: 0.65rem;
}

@media screen and (max-aspect-ratio: '3/4') {
  *{
    font-size: 16px;
  }
}
@media screen and (min-aspect-ratio: '4/3') {
  *{
    font-size: calc(16px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(252,191,73,1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,49,73,1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,49,73,0.8);
}

.App{
  position: relative;
  scroll-behavior: smooth;
  background:rgba(252,191,73,1);
  .container{
    @media (min-width: 990px) {
      max-width: 800px;
  }
  }
}
