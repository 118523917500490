.menu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    h3{
        color: rgba(252,191,73,1);
        letter-spacing: 2px;
        text-transform: uppercase;
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
    }
}