.parteneri-message{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 8px;
    .display-2{
        font-weight: 900;
        text-transform: uppercase;
        max-width: 14ch;
    }
    p{
        margin-bottom: 16px;
    }
    .swiper{
        background-color: rgba(252,191,73,1);
        height: 200px;
        width: 100%;
        .slide{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}