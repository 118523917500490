.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .newsletter-message{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        gap: 8px;
        z-index: 1000;
        @media (min-width: 768px) {
            width: 70%;
        }
        .display-2{
            font-weight: 900;
            text-transform: uppercase;
            max-width: 14ch;
        }
        p{
            margin-bottom: 16px;
        }
    }
    .air{
        display: flex;
        position: absolute;
        @media (min-width: 768px) {
            width: 50%;
        }
        svg{
            height: auto;
            width: 200px;
            fill: rgba(255, 255, 255, 0.2);
        }

    }
}