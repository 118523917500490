.cluster-message{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    z-index: 1000;
    h1{
        font-weight: 900;
        line-height: 90%;
        text-transform: uppercase;
        margin-bottom: 8px;
        max-width: 17ch;
    }
}
.pinpoints{
    margin-top: 32px;
    display: flex;
    gap: 32px;
    z-index: 1000;
    @media (min-width: 768px) {
        flex-direction: column;
        margin-top: 0;
    }
    a{
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: auto;
        svg{
            margin-left: auto;
            margin-right: auto;
            fill: rgba(0,49,73,1);
        }
        p{
            text-align: center;
        }
    }
    .pinpoint-one, .pinpoint-two{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        .point{
            position: relative;
            display: flex;
            gap: 24px;
            svg{
                width: 50px;
                height: auto;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}