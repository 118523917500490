.modal-wrapper{
    position: relative;
    p{
        color: white;
        position: fixed;
        top: 32px;
        left: 50%;
        z-index: 3001;
        background-color: red;
        padding: 2px 8px;
        text-transform: uppercase;
        transform: translateX(-50%);
        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }
    }
    .modal-video{
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.85);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}