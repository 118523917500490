.terms-page{
    width: 100%;
    min-height: 100vh;
    align-items: center;
    margin: auto;
    overflow: scroll;
    @media screen and (max-aspect-ratio: '3/4') {
        padding: calc(1/12 * 100vh) calc(1/12 * 100vw);
    }
    @media screen and (min-aspect-ratio: '4/3') {
        padding: calc(2/12 * 100vh) calc(1/12 * 100vw);
    }
    .container{
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-left: auto;
        margin-right: auto;
    }
    .scrollTop{
        position: fixed;
        bottom: 32px;
        right: 32px;
        background-color: rgba(0,49,73,1);
        border-radius: 50%;
        padding: 16px;
        box-shadow: -3px 3px 30px 10px rgba(0,0,0,0.2);
        svg{
            width: 16px;
            height: 16px;
            fill: rgba(252,191,73,1);
        }
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
    }
}