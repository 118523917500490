nav{
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 2100;
    top: 0;
    left: 0;
    right: 0;
    .navigation{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px;
        z-index: 2102;
        @media (min-width: 768px) {
            padding: 32px 32px;
        }
        @media (min-width: 990px) and (max-aspect-ratio: '3/4') {
            padding: calc(1/12 * 100vw);
        }
        @media (min-width: 990px) and (min-aspect-ratio: '4/3') {
            padding: calc(0.5/12 * 100vh) calc(1/12 * 100vh);
        }
        @media (min-width: 1200px) and (min-aspect-ratio: '4/3') {
            padding: calc(0.5/12 * 100vh) calc(1/12 * 100vh);
        }
        button{
            position: relative;
            width: 24px;
            height: 24px;
            margin-left: auto;
            background-color: transparent;
            border: none;

            outline: none;
            svg{
                width: 100%;
                height: auto;
            }
            &:hover{
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
    .collapse-area{
        background-color: rgba(0,49,73,1);
        background-image: url('../../assets/construction.jpg');
        background-blend-mode: darken;
        background-position: center;
        background-size: cover;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1010;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 64px;
        color: rgba(252,191,73,1);
    }
}