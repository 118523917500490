.benefit{
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
    &:last-of-type{
        @media (min-width:990px) {
            margin-bottom: 32px;
        }
    }
    .benefit-header{
        padding-bottom: 16px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .benefit-body{
        display: flex;
        .benefit-icon{
            img{
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
        .benefit-description{
            padding-left: 16px;
            max-width: 28ch;
        }
    }
}